import { FC, createElement, useMemo } from 'react';
import { FiX } from 'react-icons/fi';

import { IModal, IModalConfig } from '@pra-vendas-themes/interfaces/IModal';

import {
  Wrapper,
  Overlay,
  Header,
  Title,
  Content,
  WrapperModal,
  ButtonClose,
} from './styles';

const Modal: FC<IModal> = ({ modal, handleClose }) => {
  const { component, config } = modal || {};
  const { onClose, title, size, props } = config || {};

  function handleOnClose(...params: any): void {
    handleClose();
    if (onClose) onClose(...params);
  }

  const isVisible = useMemo(() => {
    return !!modal && !modal?.isClosed;
  }, [modal]);

  return (
    <Wrapper className={isVisible ? 'isVisible' : ''}>
      <Overlay onClick={handleClose} />
      <WrapperModal size={size}>
        <Header>
          {title && <Title>{title}</Title>}
          <ButtonClose onClick={handleClose}>
            <FiX />
          </ButtonClose>
        </Header>
        <Content>
          {!!component &&
            createElement<IModalConfig>(component, {
              onClose: onClose ? handleOnClose : null,
              handleClose,
              ...(props || {}),
            })}
        </Content>
      </WrapperModal>
    </Wrapper>
  );
};

export default Modal;
