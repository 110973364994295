import styled, { ModalSizesScheme } from 'styled-components';

type TWrapperModal = {
  size?: ModalSizesScheme;
};

export const WrapperModal = styled.div<TWrapperModal>`
  position: relative;
  background-color: ${(p) => p.theme.colors.background};
  color: #000;

  border-radius: ${(p) => p.theme.borderRadius};

  max-width: ${(p) => (p.size ? p.theme.modal[p.size] : '100%')};
  width: 100%;
  margin: auto 0;
  z-index: 1001;

  transform: translateY(15%);
  opacity: 0;

  transition: opacity 0.3s, transform 0.3s;
  will-change: opacity, transform;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;

  overflow: auto;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.large};

  background-color: rgba(0, 0, 0, 0.5);

  opacity: 0;

  transition: opacity 0.3s;
  will-change: opacity;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;

  overflow: auto;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px;

  visibility: hidden;
  transition: visibility 0.3s;

  &.isVisible {
    visibility: visible;

    & ${WrapperModal} {
      transform: translateY(0);
      opacity: 1;
    }

    & ${Overlay} {
      opacity: 1;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing.large};

  border-bottom: 1px solid ${(p) => p.theme.colors.border};
`;

export const Content = styled.div`
  color: #000;
  padding: ${(p) => p.theme.spacing.large};
`;

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: ${(p) => p.theme.font.medium};
  color: ${(p) => p.theme.colors.textPrimary};
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  color: ${(p) => p.theme.colors.textPrimary};
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.font.xLarge};

  & svg {
    stroke-width: 3;
  }
`;
